import Vue from "vue";

export const store = Vue.observable({
    filter: [],
    // customers filter vars
    filter_customers: [],
    filter_enquiries: [],
    columns: [
        {
            'id'     : 1,
            'status' : false,
            'title'  : 'Gender',
            'title_db' : 'gender'
        },
        {
            'id'     : 2,
            'status' : false,
            'title'  : 'Titel',
            'title_db' : 'title'
        },
        {
            'id'     : 3,
            'status' : true,
            'title'  : 'Vorname',
            'title_db' : 'first_name'
        },
        {
            'id'     : 4,
            'status' : true,
            'title'  : 'Nachname',
            'title_db' : 'last_name'
        },
        {
            'id'     : 5,
            'status' : false,
            'title'  : 'Titel nachgestellt',
            'title_db' : 'title_tune'
        },
        {
            'id'     : 6,
            'status' : false,
            'title'  : 'Beruf/Funktion',
            'title_db' : 'profession'
        },
        {
            'id'     : 7,
            'status' : true,
            'title'  : 'Geburtsdatum',
            'title_db' : 'checkout_date'
        },

        {
            'id'     : 8,
            'status' : false,
            'title'  : 'Status',
            'title_db' : 'status'
        },
        {
            'id'     : 9,
            'status' : false,
            'title'  : 'Firmenname',
            'title_db' : 'firm_name'
        },
        {
            'id'     : 10,
            'status' : false,
            'title'  : 'Adresse',
            'title_db' : 'firm_address'
        },
        {
            'id'     : 11,
            'status' : false,
            'title'  : 'Stadt/Ort',
            'title_db' : 'city'
        },
        {
            'id'     : 12,
            'status' : false,
            'title'  : 'Bundesland/Landkreis/Region',
            'title_db' : 'region'
        },
        {
            'id'     : 13,
            'status' : false,
            'title'  : 'PLZ',
            'title_db' : 'postcode'
        },
        {
            'id'     : 14,
            'status' : false,
            'title'  : 'Land',
            'title_db' : 'country'
        },
        {
            'id'     : 15,
            'status' : false,
            'title'  : 'Telefon',
            'title_db' : 'phone'
        },
        {
            'id'     : 16,
            'status' : true,
            'title'  : 'E-Mail',
            'title_db' : 'email'
        },

        {
            'id'     : 17,
            'status' : false,
            'title'  : 'Anmerkungen',
            'title_db' : 'message'
        },

        {
            'id'     : 18,
            'status' : true,
            'title'  : 'Workshop',
            'title_db' : 'workshop'
        },

        {
            'id'     : 19,
            'status' : false,
            'title'  : 'Enquiry Type',
            'title_db' : 'enquiriable_type'
        },

        {
            'id'     : 20,
            'status' : false,
            'title'  : 'Enquiry Link',
            'title_db' : 'enquiriable'
        },
    ],
    old_filter_views_default : [
        { // Default View! Not remove! All others - add!
            title : 'Default View',
            slug  : 'default_view',
            type  : 'customers',
            filters_data : {
                'gender'        : '',
                'title'         : '',
                'first_name'    : '',
                'last_name'     : '',
                'title_tune'    : '',
                'profession'    : '',
                'checkout_date_from' : '',
                'checkout_date_to' : '',
                'status'        : '',
                'firm_name'     : '',
                'firm_address'  : '',
                'city'          : '',
                'region'        : '',
                'postcode'      : '',
                'country'       : '',
                'phone'         : '',
                'email'         : '',
                'message'       : '',
                'workshop'      : ''
            },
            columns_data : [
                {
                    'id'     : 1,
                    'status' : false,
                    'title'  : 'Gender',
                    'title_db' : 'gender'
                },
                {
                    'id'     : 2,
                    'status' : false,
                    'title'  : 'Titel',
                    'title_db' : 'title'
                },
                {
                    'id'     : 3,
                    'status' : true,
                    'title'  : 'Vorname',
                    'title_db' : 'first_name'
                },
                {
                    'id'     : 4,
                    'status' : true,
                    'title'  : 'Nachname',
                    'title_db' : 'last_name'
                },
                {
                    'id'     : 5,
                    'status' : false,
                    'title'  : 'Titel nachgestellt',
                    'title_db' : 'title_tune'
                },
                {
                    'id'     : 6,
                    'status' : false,
                    'title'  : 'Beruf/Funktion',
                    'title_db' : 'profession'
                },
                {
                    'id'     : 7,
                    'status' : true,
                    'title'  : 'Geburtsdatum',
                    'title_db' : 'checkout_date'
                },

                {
                    'id'     : 8,
                    'status' : false,
                    'title'  : 'Status',
                    'title_db' : 'status'
                },
                {
                    'id'     : 9,
                    'status' : false,
                    'title'  : 'Firmenname',
                    'title_db' : 'firm_name'
                },
                {
                    'id'     : 10,
                    'status' : false,
                    'title'  : 'Adresse',
                    'title_db' : 'firm_address'
                },
                {
                    'id'     : 11,
                    'status' : false,
                    'title'  : 'Stadt/Ort',
                    'title_db' : 'city'
                },
                {
                    'id'     : 12,
                    'status' : false,
                    'title'  : 'Bundesland/Landkreis/Region',
                    'title_db' : 'region'
                },
                {
                    'id'     : 13,
                    'status' : false,
                    'title'  : 'PLZ',
                    'title_db' : 'postcode'
                },
                {
                    'id'     : 14,
                    'status' : false,
                    'title'  : 'Land',
                    'title_db' : 'country'
                },
                {
                    'id'     : 15,
                    'status' : false,
                    'title'  : 'Telefon',
                    'title_db' : 'phone'
                },
                {
                    'id'     : 16,
                    'status' : true,
                    'title'  : 'E-Mail',
                    'title_db' : 'email'
                },

                {
                    'id'     : 17,
                    'status' : false,
                    'title'  : 'Anmerkungen',
                    'title_db' : 'message'
                },

                {
                    'id'     : 18,
                    'status' : true,
                    'title'  : 'Workshop(s)',
                    'title_db' : 'workshop'
                },
            ],
            sort_field : 'id',
            sort_type  : 'asc',
        },
    ],
    old_filter_views : [],
    old_filter_views_default_enquiries : [
        { // Default View! Not remove! All others - add!
            title : 'Default View',
            slug  : 'default_view',
            type  : 'enquiries',
            filters_data : {
                'email'         : '',
                'message'       : '',
                'gender'        : '',
                'title'         : '',
                'first_name'    : '',
                'last_name'     : '',
                'title_tune'    : '',
                'profession'    : '',
                'created_at_from' : '',
                'created_at_to' : '',
                'status'        : '',
                'firm_name'     : '',
                'firm_address'  : '',
                'city'          : '',
                'region'        : '',
                'postcode'      : '',
                'country'       : '',
                'phone'         : '',
            },
            columns_data : [
                {
                    'id'     : 16,
                    'status' : true,
                    'title'  : 'E-Mail',
                    'title_db' : 'email'
                },

                {
                    'id'     : 17,
                    'status' : true,
                    'title'  : 'Anmerkungen',
                    'title_db' : 'message'
                },

                {
                    'id'     : 19,
                    'status' : true,
                    'title'  : 'Enquiry Type',
                    'title_db' : 'enquiriable_type'
                },

                {
                    'id'     : 20,
                    'status' : true,
                    'title'  : 'Module Link',
                    'title_db' : 'enquiriable'
                },

                {
                    'id'     : 21,
                    'status' : true,
                    'title'  : 'Location',
                    'title_db' : 'locations'
                },

                {
                    'id'     : 1,
                    'status' : true,
                    'title'  : 'Gender',
                    'title_db' : 'gender'
                },
                {
                    'id'     : 2,
                    'status' : true,
                    'title'  : 'Titel',
                    'title_db' : 'title'
                },
                {
                    'id'     : 3,
                    'status' : true,
                    'title'  : 'Vorname',
                    'title_db' : 'first_name'
                },
                {
                    'id'     : 4,
                    'status' : true,
                    'title'  : 'Nachname',
                    'title_db' : 'last_name'
                },
                {
                    'id'     : 5,
                    'status' : true,
                    'title'  : 'Titel nachgestellt',
                    'title_db' : 'title_tune'
                },
                {
                    'id'     : 6,
                    'status' : true,
                    'title'  : 'Beruf/Funktion',
                    'title_db' : 'profession'
                },
                {
                    'id'     : 7,
                    'status' : true,
                    'title'  : 'Geburtsdatum',
                    'title_db' : 'birthday'
                },
                {
                    'id'     : 8,
                    'status' : true,
                    'title'  : 'Status',
                    'title_db' : 'status'
                },
                {
                    'id'     : 9,
                    'status' : true,
                    'title'  : 'Firmenname',
                    'title_db' : 'firm_name'
                },
                {
                    'id'     : 10,
                    'status' : true,
                    'title'  : 'Adresse',
                    'title_db' : 'firm_address'
                },
                {
                    'id'     : 11,
                    'status' : true,
                    'title'  : 'Stadt/Ort',
                    'title_db' : 'city'
                },
                {
                    'id'     : 12,
                    'status' : true,
                    'title'  : 'Bundesland/Landkreis/Region',
                    'title_db' : 'region'
                },
                {
                    'id'     : 13,
                    'status' : true,
                    'title'  : 'PLZ',
                    'title_db' : 'postcode'
                },
                {
                    'id'     : 14,
                    'status' : true,
                    'title'  : 'Land',
                    'title_db' : 'country'
                },
                {
                    'id'     : 15,
                    'status' : true,
                    'title'  : 'Telefon',
                    'title_db' : 'phone'
                },
                {
                    'id'     : 22,
                    'status' : true,
                    'title'  : 'Datum',
                    'title_db' : 'created_at'
                },
                {
                    'id'     : 23,
                    'status' : true,
                    'title'  : 'Enquiry Status',
                    'title_db' : 'enquiry_status'
                },
            ],
            sort_field : 'id',
            sort_type  : 'asc',
        },
    ],
    countries : [],
    workshops : [],
    sort_item : null,
});

export const mutations = {
    async getAllFilters() {
        await window.axios
            .get('/api/filter/init')
            .then((response) => {
                store.filter = response.data;
            });
    },

    async getSearchFilters(data_to_server) {
        await window.axios.post(`/api/filter/search`, {
                'data_to_server' : data_to_server,
            })
            .then((response) => {
                store.filter = response.data;
            });
    },

    // ******** Customers Filter (Admin) ********
    async getAllCountries() {
        await window.axios
            .get('/api/filterCustomers/getAllCountries')
            .then((response) => {
                store.countries = response.data;
            });
    },

    async getAllWorkshops() {
        await window.axios
            .get('/api/filterCustomers/getAllWorkshops')
            .then((response) => {
                store.workshops = response.data;
            });
    },

    async getAllCustomersFilter() {
        await window.axios
            .get('/api/filterCustomers/init')
            .then((response) => {
                store.filter_customers = response.data;
            });
    },
    async getCustomersFilter(data_to_server, sort_field, sort_type) {
        await window.axios
            .post(`/api/filterCustomers/search`, {
                'data_to_server' : data_to_server,
                'sort_field'     : sort_field,
                'sort_type'      : sort_type,
                'column_data'    : store.columns
            })
            .then((response) => {
                store.filter_customers = response.data;
            });
    },

    async getOldFormList() {
        await window.axios
            .get('/api/filterCustomers/getViewForm')
            .then((response) => {
                store.old_filter_views = store.old_filter_views_default.concat(JSON.parse(response.data));
            });
    },

    async createViewForm(form) {
        let _this = this;
        await window.axios.post(`/api/filterCustomers/createViewForm`, {
                'token'       : form["_token"].value,
                'title'       : form["title"].value,
                'export_type' : form["export_type"].value,
                'filter_data' : form["filter_data"].value,
                'column_data' : form["column_data"].value,
                'sort_item'   : store.sort_item
            })
            .then((response) => {
                //response.data;
                _this.getOldFormList();
            })
            .catch(function (error) {
                alert(error.message);
            });
    },

    async updateViewForm(token, slug, title) {
        let _this = this;
        await window.axios
            .post(`/api/filterCustomers/updateViewForm`, {
                'token' : token,
                'slug'  : slug,
                'title' : title,
            })
            .then((response) => {
                if(response.data !== 'success') {
                    let error_text = '';
                    Object.keys(response.data).forEach(function(key) {
                        error_text += response.data[key] + '\n';
                    });
                    alert(error_text);
                } else {
                    _this.getOldFormList();
                }
            });
    },

    async deleteViewForm(token, slug) {
        let _this = this;
        await window.axios
            .post(`/api/filterCustomers/deleteViewForm`, {
                'token' : token,
                'slug'  : slug,
            })
            .then((response) => {
                _this.getOldFormList();
            });
    },

    async sortedData(filed) {
        if (!store.filter_customers.server_answer) return;

        let data_to_server = {
                'gender' : '',
                'title' : '',
                'first_name' : '',
                'last_name' : '',
                'title_tune' : '',
                'profession' : '',
                'checkout_date_from' : '',
                'checkout_date_to' : '',
                'status' : '',
                'firm_name' : '',
                'firm_address' : '',
                'city' : '',
                'region' : '',
                'postcode' : '',
                'country' : '',
                'phone' : '',
                'email' : '',
                'message' : '',
                'workshop' : ''
            };

        if(!store.sort_item || (store.sort_item.field === filed && store.sort_item.type === 'desc')) {
            store.sort_item = {
                'field' : filed,
                'type'  : 'asc',
            };
        } else {
            store.sort_item = {
                'field' : filed,
                'type'  : 'desc',
            };
        }

        await window.axios.post(`/api/filterCustomers/search`, {
                'data_to_server' : data_to_server,
                'column_data'    : store.columns,
                'sort_field'     : store.sort_item.field,
                'sort_type'      : store.sort_item.type
            })
            .then((response) => {
                store.filter_customers = response.data;
            });
    },

    // ******** Enquiries Filter (Admin) ********
    async getAllEnquiriesFilter() {
        await window.axios
            .get('/api/filterEnquiries/init')
            .then((response) => {
                store.filter_enquiries = response.data;
            });
    },
    async getEnquiriesFilter(data_to_server, sort_field, sort_type) {
        await window.axios
            .post(`/api/filterEnquiries/search`, {
                'data_to_server' : data_to_server,
                'sort_field'     : sort_field,
                'sort_type'      : sort_type,
                'column_data'    : store.old_filter_views_default_enquiries[0].columns_data
            })
            .then((response) => {
                store.filter_enquiries = response.data;
            });
    },

    async getEnquiryFormList() {
        await window.axios
            .get('/api/filterEnquiries/getViewForm')
            .then((response) => {
                store.old_filter_views = store.old_filter_views_default_enquiries.concat(JSON.parse(response.data));
            });
    },

    async createEnquiryViewForm(form) {
        let _this = this;
        await window.axios.post(`/api/filterEnquiries/createViewForm`, {
                'token'       : form["_token"].value,
                'title'       : form["title"].value,
                'export_type' : form["export_type"].value,
                'filter_data' : form["filter_data"].value,
                'column_data' : form["column_data"].value,
                'sort_item'   : store.sort_item
            })
            .then((response) => {
                //response.data;
                _this.getOldFormList();
            })
            .catch(function (error) {
                alert(error.message);
            });
    },

    async updateEnquiryViewForm(token, slug, title) {
        let _this = this;
        await window.axios
            .post(`/api/filterEnquiries/updateViewForm`, {
                'token' : token,
                'slug'  : slug,
                'title' : title,
            })
            .then((response) => {
                if(response.data !== 'success') {
                    let error_text = '';
                    Object.keys(response.data).forEach(function(key) {
                        error_text += response.data[key] + '\n';
                    });
                    alert(error_text);
                } else {
                    _this.getOldFormList();
                }
            });
    },

    async deleteEnquiryViewForm(token, slug) {
        let _this = this;
        await window.axios
            .post(`/api/filterEnquiries/deleteViewForm`, {
                'token' : token,
                'slug'  : slug,
            })
            .then((response) => {
                _this.getOldFormList();
            });
    },

    async sortedDataEnquiry(filed) {
        if (!store.filter_enquiries.server_answer) return;

        let data_to_server = {
                'gender' : '',
                'title' : '',
                'first_name' : '',
                'last_name' : '',
                'title_tune' : '',
                'profession' : '',
                'checkout_date_from' : '',
                'checkout_date_to' : '',
                'status' : '',
                'firm_name' : '',
                'firm_address' : '',
                'city' : '',
                'region' : '',
                'postcode' : '',
                'country' : '',
                'phone' : '',
                'email' : '',
                'message' : '',
                'workshop' : ''
            };

        if(!store.sort_item || (store.sort_item.field === filed && store.sort_item.type === 'desc')) {
            store.sort_item = {
                'field' : filed,
                'type'  : 'asc',
            };
        } else {
            store.sort_item = {
                'field' : filed,
                'type'  : 'desc',
            };
        }

        await window.axios.post(`/api/filterEnquiries/search`, {
                'data_to_server' : data_to_server,
                'column_data'    : store.old_filter_views_default_enquiries[0].columns_data,
                'sort_field'     : store.sort_item.field,
                'sort_type'      : store.sort_item.type
            })
            .then((response) => {
                store.filter_enquiries = response.data;
            });
    },
    
};
